<template>
  <div style="padding:16px">

    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" justify="space-around">

        <el-col :span="18">
          <el-button type="primary" @click="newBtn">新建</el-button>
        </el-col>
        <el-col :span="6">
          <el-input placeholder="请输入内容" v-model="queryInfo.condition.queryKey" class="input-with-select" clearable>
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%"
      height="calc(100vh - 220px)">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
          <el-table-column label="风速（m/s）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.speed }}</template>
          </el-table-column>
          <el-table-column label="风切向" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.direct }}</template>
          </el-table-column>
          <el-table-column label="年发电量（万kwh）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.power }}</template>
          </el-table-column>
          <el-table-column label="风功率密度（w/m2）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.density }}</template>
          </el-table-column>
          <el-table-column label="省名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.provinceName }}</template>
          </el-table-column>
          <el-table-column label="市名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.cityName }}</template>
          </el-table-column>
          <!-- <el-table-column label="区县名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.areaName }}</template>
          </el-table-column> -->
          <el-table-column label="创建日期" min-width="140px" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createDt }}</template>
          </el-table-column>
        <el-table-column label="操作" width="135" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
            <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.id)">
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 400]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="(labelType=='add'?'新增':'编辑')" :visible.sync="showDialog" width="800px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">
          <el-col :span="12">
              <el-form-item label="省名称：" prop="siteName">
                <el-select value-key="id"
                 @change="addressChane('province')" v-model="form.provinceObj">
                  <el-option v-for="item in provinceOptions" :label="item.name" :value="{id:item.id,provinceName:item.name,provinceCode:item.id}" :key="item.id"></el-option>
                </el-select>

              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="市名称：" prop="siteName">
                <el-select value-key="id"
                @change="addressChane('city')" v-model="form.cityObj">
                  <el-option v-for="item in cityOptions" :label="item.name" :value="{cityName:item.name,id:item.id,cityCode:item.id}" :key="item.id"></el-option>
                </el-select>

              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="区县名称：" prop="siteName">
                <el-select value-key="id" @change="addressChane('area')"  v-model="form.areaObj">
                  <el-option v-for="item in areaOptions"
                  :label="item.name" :value="{id:item.id,areaName:item.name,areaCode:item.id}" :key="item.id"></el-option>
                </el-select>

              </el-form-item>
            </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="省市区：">
              <el-cascader @change="addressChange"  v-model="address" :props="props"></el-cascader>
            </el-form-item>
          </el-col> -->
            <el-col :span="12">
              <el-form-item label="风速（m/s）：" prop="siteName">
                <el-input  v-model="form.speed" placeholder="请输入风速"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="风切向：" prop="siteName">
                <el-input v-model="form.direct" placeholder="请输入风切向"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="年发电量（万kwh）：" prop="siteName">
                <el-input v-model="form.power" placeholder="请输入年发电量"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="风功率密度（w/m2）：" prop="siteName">
                <el-input v-model="form.density" placeholder="请输入风功率密度"></el-input>
              </el-form-item>
            </el-col>

        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      setByOwn:true,
      address:"",
      provinceOptions:[],
      cityOptions:[],
      areaOptions:[],
      labelType: 'add',
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {}
      },
      form: {
        id:"",
        provinceObj:'',
        cityObj:"",
        areaObj:'',
        density:"",
      direct:"",
      power:"",
      speed:""
      },
      showDialog: false,
      tableData: [],
    };
  },
 watch:{
//   'form.provinceObj':{
//   async  handler(v){
//     console.log('改变')
//         if(v!=''){
//           let res= await this.getCityOptions(this.form.provinceObj.id)
//           this.cityOptions=res
//           this.areaOptions=[]
//           this.form.cityObj=''
//           this.form.areaObj=''
//         }

//   }
// }
 },
  created () {
    this.getList()
    this.getProvinceOptions()
  },
  computed:{
    props(){
      var that=this
      return {
          lazy: true,
        async  lazyLoad (node, resolve) {
            const { level } = node;
            if(level==0){
              let arr=await that.getProvinceOptions()
              arr.forEach(item=>{
                item.label=item.name,
                item.value =item.name,
                item.leaf= false
              })
              resolve(arr)
            }
            if(level==1){
             let parentId=node.data.id
              let res=await that.$http.post('area/queryList',{parentId })
              let arr=JSON.parse(JSON.stringify(res.data.data))
              arr.forEach(item=>{
                item.label=item.name,
                item.value =item.name,
                item.leaf= true
              })
              resolve(arr)
            }
            // if(level==2){
            //  let parentId=node.data.id
            //   let res=await that.$http.post('area/queryList',{parentId })
            //   let arr=JSON.parse(JSON.stringify(res.data.data))
            //   arr.forEach(item=>{
            //     item.label=item.name,
            //     item.value =item.name,
            //     item.leaf= true
            //   })
            //   resolve(arr)
            // }
          }
      }
    }
   },

  methods: {
  async  addressChane(address){
      if(address=='province'){
        if(this.form.provinceObj!=''){
          let res= await this.getCityOptions(this.form.provinceObj.id)
          this.cityOptions=res
         if(this.setByOwn){
          this.areaOptions=[]
          this.form.cityObj=''
          this.form.areaObj=''
         }
        }
      }
      if(address=='city'){
        if(this.form.cityObj!=''){
          let res= await this.getCityOptions(this.form.cityObj.id)
          this.areaOptions=res
         if(this.setByOwn){
          this.form.areaObj=''

         }



        }
      }
      this.$nextTick(()=>{
      this.$forceUpdate()
    })
    },
    // addressChange(){
    //   console.log(this.address)
    //   if(Array.isArray(this.address)){
    //     this.form.provinceName=this.address[0]
    //     this.form.cityName=this.address[1]
    //     this.form.areaName=this.address[2]
    //     this.form.areaName=''
    //   }else{
    //     this.form.provinceName=''
    //     this.form.cityName=''
    //     this.form.areaName=''
    //   }
    //   this.$nextTick(()=>{
    //     this.$forceUpdate()
    //   })
    // },
   async  getProvinceOptions(){
    let res=await this.$http.post('area/queryList',{level:1})
    this.provinceOptions=res.data.data
    return res.data.data
     },
     async getCityOptions(parentId){
      let res=await this.$http.post('area/queryList',{parentId })
      return res.data.data
     },

    //获取数据
    getList () {
	  var that = this;
	  that.$http.post("/windSpeed/list", that.queryInfo).then(function (response) {
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },

    // 保存
    handleSave () {
	    var that = this;
      let data={}
      data.id=this.form.id
      data.density=this.form.density
      data.direct=this.form.direct
      data.power=this.form.power
      data.speed=this.form.speed
      data.provinceName=this.form.provinceObj.provinceName || ''
      data.provinceCode=this.form.provinceObj.id || ''
      data.cityName=this.form.cityObj.cityName || ''
      data.cityCode=this.form.cityObj.cityCode || ''
      data.areaName=this.form.areaObj.areaName || ''
      data.areaCode=this.form.areaObj.areaCode || ''
      if(that.labelType=='add'){
        delete data.id
      }
	  that.$http.post("/windSpeed/save", data).then(function (response) {
		  if (response.data.code == 200) {
			  that.$notify.success({
				title: "提示",
				message: "保存成功",
				showClose: true,
			  });
			 that.showDialog = false
			  that.getList()
		  }else{
			  that.$notify.info({
				title: "提示",
				message: response.data.message,
				showClose: true,
			  });
		  }
      }).catch(function(error){


      })


    },

    //修改
async   edit (row) {
      this.showDialog = true;
      let res= await this.getCityOptions(row.provinceCode)
      this.cityOptions=res
      let ress= await this.getCityOptions(row.cityCode)
      this.areaOptions=ress
      this.labelType = 'edit'
      this.form.id=row.id
      this.form.density=row.density,
      this.form.direct=row.direct,
      this.form.power=row.power,
      this.form.speed=row.speed
      this.setByOwn=false
      this.form.provinceObj={id:row.provinceCode,provinceName:row.provinceName,provinceCode:row.provinceCode}
      this.form.cityObj={id:row.cityCode,cityName:row.cityName,provinceCode:row.cityCode}
      this.form.areaObj={id:row.areaCode,areaName:row.areaName,areaCode:row.areaCode}
      this.setByOwn=true
    },


	// 删除用户
    async handleDelte(id) {
           var that = this;
        that.$http.post("/windSpeed/delete" ,{"id":id}).then(function (response) {
			if(response.data.code == 200){
			 that.$notify.success({
				title: "提示",
				message: "删除成功",
				showClose: true,
			  });
			  that.getList();
			}else{
				 that.$notify.info({
					title: "提示",
					message: response.data.message,
					showClose: true,
				  });
			}

        });
    },

    //新增按钮
    newBtn () {
      this.labelType = 'add'
      this.showDialog = true;
      this.form = {
        id:"",
        provinceObj:'',
        cityObj:"",
        areaObj:'',
        density:"",
      direct:"",
      power:"",
      speed:""
      }
    },

    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },

  },
};
</script>

<style scoped>
</style>

